<template>
 <div id="plan1" class="plan" v-title :data-title="title">
   <page-template :carousel-list="carouselList"
                  type="product"
                  :plan1-active="plan1Active"
                  :plan1-details="plan1Details"
                  :plan2-title="plan2Title"
                  :plan2-list="plan2List"
                  :plan3-list="plan3List"
                  />
 </div>
</template>

<script>
import PageTemplate from '../components/template.vue'
import Public from "@/assets/js/public";
export default {
  name: 'Product2',
  components: {
   PageTemplate
  },
  mixins: [],
  data () {
    return {
      title: this.$route.query.title || '产品服务',
      // 轮播列表
      carouselList: [
        { img: require('@/assets/images/08.fw.png'), txt: '一站式学生社区', txt2: '', txt3: '综合管理服务平台'},
      ],
      plan1Active: Public.product2().routerName,
      // 当前方案概述详情
      plan1Details:Public.product2(),
      plan2Title: {
        title: '产品亮点', txt: 'Product Highlights'
      },
      // 我们的经验
      plan2List: [
        {img: require('@/assets/images/28.fw.png'), title: '高度自动化管理', txt: '解放辅导员日常繁琐的学生管理工作，各种校级任务一键执行'},
        {img: require('@/assets/images/27.fw.png'), title: '双组织架构管理', txt: '支持学院+书院双组织架构管理，针对学生两种身份，管理宿舍、组织活动、通知等'},
        {img: require('@/assets/images/26.fw.png'), title: '移动化管理', txt: '支持微信、钉钉、学校官方App等多端接入，通过手机即可处理事项。'},
        {img: require('@/assets/images/25.fw.png'), title: '灵活扩展', txt: '因校制宜，根据每个学校业务特点，提供针对性的定制解决方案'},
      ],
      // 服务案例
      plan3List: [
        {img: require('@/assets/images/school/kf.jpg'), title: '河南开放大学', txt: '前身是创办于1979年的河南广播电视大学，2020年11月正式更名为河南开放大学'},
        {img: require('@/assets/images/school/zs.jpg'), title: '周口师范学院', txt: '学校始建于1973年，2002年经教育部批准升格为本科院校。学校现占地1600余亩'},
        {img: require('@/assets/images/school/zw.jpg'), title: '周口文理职业学院', txt: '是2021年经河南省政府批准成立，教育部备案的公办专科层次全日制普通高校'},
      ]
    };
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {

  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
</style>
